<template>
  <CRow>
    <CCol cols="12" lg="12" class="pb-0 pt-0">
      <b-table class="data_table"
              :data="productData"
              :striped="true"
              :hoverable="true"
              :mobile-cards="true"
              :paginated="isPaginated"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :pagination-position="paginationPosition"                 
              :default-sort-direction="defaultSortDirection"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize"
              default-sort="user_points_spent">
        
        <template slot-scope="props">
          <b-table-column field="product_name" sortable :label="$t('common.Product')" :searchable="searchEnabled" width="40%">
            <div class="d-flex align-items-center">
              <div v-if="props.row.product_image_id && props.row.from_marketplace === 'N'"
                  class="store_product_image item_image d-inline-block align-middle mr-2"
                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
              </div>
              <div v-else-if="props.row.product_image_id && props.row.from_marketplace === 'Y'"
                  class="store_product_image item_image d-inline-block align-middle mr-2"
                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-store-product-mp/' + props.row.loyalty_store_product_id_external + '/' + props.row.product_image_id + '.jpg' + '/' + clientToken + ')' }">
              </div>                    
              <div v-else-if="props.row.loyalty_store_partner_product_external_id"
                  class="item_image item_image d-inline-block align-middle mr-2"
                  v-bind:style="{ backgroundImage: 'url(' + props.row.loyalty_store_partner_product_image_url + ')' }">
              </div>
              <div v-else class="store_product_icon item_icon d-inline-block align-middle mr-2"> 
                <i class="fas fa-box"/>
              </div>
              <div>
                <span>{{props.row.product_name}}</span>
                <span class="d-block mt-1 meta" v-html="props.row.variation_name"></span>
              </div>                        
            </div>
          </b-table-column>
          <b-table-column field="loyalty_store_category_name" sortable :label="$t('common.Category')" width="30%">
            <span>{{ props.row.loyalty_store_category_name }}</span>
          </b-table-column>
          <b-table-column field="user_points_spent" sortable :label="$t('common.Coins_spent')" width="20%">
            <div class="d-flex align-items-center credits">
              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
              <span>{{props.row.user_points_spent}} {{props.row.user_points_spent !== 1 ? $t('common.coins') : $t('common.coin')}}</span>
            </div>
          </b-table-column>
          <b-table-column field="orders_amount" sortable :label="$t('insights.Nr_or_orders')" width="15%">
            <span>{{ props.row.orders_amount }}</span>
          </b-table-column>          
        </template>
        <template slot="empty">
          <div class="text-center">
            <span>{{ $t('insights.No_products_ordered') }}</span>
          </div>                
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: 'ProductsTable',
  props: ['products', 'environmentTag'],
  watch: {
    $props: {
      handler() {
        if(this.products) {
          this.productData = Object.values(this.products);
          // Check if the pagination should be activated
          (this.productData.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      productData: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      searchEnabled: false,
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
    }
  }, 	
  mounted:function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }
}
</script>
